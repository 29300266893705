import * as React from "react"
import Header from "./header"
import Footer from "./footer"
import Head from "./head"
import "../styles.css"
import CookieBanner from "react-cookie-banner"
import { theme } from "../theme.css"
import * as styles from "./layout.css"

interface LayoutProps {
  title: string
  description?: string
  image?: { id: string; url: string }
  children: React.ReactElement | React.ReactElement[]
}

const Layout: React.FC<LayoutProps> = (props) => {
  return (
    <>
      {/*<Head {...props} />*/}
      <Header />
      {props.children}
      <Footer />
      <div
        style={{ position: "fixed", bottom: "0", zIndex: 999, width: "100%" }}
      >
        <CookieBanner
          styles={{
            banner: { backgroundColor: theme.colors.primary },
            message: { fontWeight: 400, color: "white" },
            button: { backgroundColor: "white" },
          }}
          message="Durch die weitere Nutzung der Seite stimmst du der Verwendung von Cookies zu."
          onAccept={() => {}}
          dismissOnScroll={false}
          buttonMessage="Akzeptieren"
          cookie="user-has-accepted-cookies"
        />
      </div>
    </>
  )
}

export default Layout
