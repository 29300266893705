import * as React from "react"
import { Instagram, Facebook } from "react-feather"
import {
  Container,
  Flex,
  FlexList,
  Box,
  Space,
  NavLink,
  Text,
  IconLink,
  VisuallyHidden,
} from "./ui"
import { StaticImage } from "gatsby-plugin-image"

const socialMedia = {
  INSTAGRAM: {
    url: "https://www.instagram.com/fco_1956/",
    name: "Instagram",
    icon: <Instagram />,
  },
  FACEBOOK: {
    url: "https://www.facebook.com/fcottenhoefen",
    name: "Facebook",
    icon: <Facebook />,
  },
}

const getSocialURL = ({ service }) => {
  const domain = socialMedia[service]?.url
  if (!domain) return false
  return `${domain}`
}

const getSocialIcon = ({ service }) => {
  return socialMedia[service]?.icon
}

const getSocialName = ({ service }) => {
  return socialMedia[service]?.name
}

interface FooterData {
  links: { id: string; href: string; text: string }[]
  meta: { id: string; href: string; text: string }[]
  copyright: string
  socialLinks: { id: string; service: string }[]
}

const data: FooterData = {
  links: [],
  meta: [],
  socialLinks: [
    {
      id: "1",
      service: "INSTAGRAM",
    },
    {
      id: "3",
      service: "FACEBOOK",
    },
  ],
  copyright: "© 2023 FC Ottenhöfen",
}

export default function Footer() {
  const { links, meta, socialLinks, copyright } = data

  return (
    <Box as="footer" paddingY={4}>
      <Container>
        <Flex variant="start" responsive>
          <NavLink to="/">
            <VisuallyHidden>Home</VisuallyHidden>
            <Flex>
              <StaticImage
                alt=""
                src="../images/header-fco-logo.png"
                width={50}
              />
              <Text style={{ textTransform: "initial" }} variant="medium">
                FC Ottenhöfen
              </Text>
            </Flex>
          </NavLink>
          <Space />
          <FlexList>
            {socialLinks &&
              socialLinks.map((link) => {
                const url = getSocialURL(link)
                return (
                  url && (
                    <li key={link.id}>
                      <IconLink to={url}>
                        <VisuallyHidden>{getSocialName(link)}</VisuallyHidden>
                        {getSocialIcon(link)}
                      </IconLink>
                    </li>
                  )
                )
              })}
          </FlexList>
        </Flex>
        <Flex variant="start" responsive>
          <FlexList variant="start" responsive>
            {links &&
              links.map((link) => (
                <li key={link.id}>
                  <NavLink to={link.href}>{link.text}</NavLink>
                </li>
              ))}
          </FlexList>
          <FlexList>
            {meta &&
              meta.map((link) => (
                <li key={link.id}>
                  <NavLink to={link.href}>
                    <Text variant="small">{link.text}</Text>
                  </NavLink>
                </li>
              ))}
          </FlexList>
          <Flex variant="center">
            <Text variant="small">{copyright}</Text>
          </Flex>
        </Flex>
      </Container>
      <Space size={3} />
    </Box>
  )
}
